import React, { useEffect, useRef, useState } from "react";
import Joi from "joi";
import bank from "../Assets/bank.png";
import card from "../Assets/card.png";
import recognition from "../Assets/recognition.png";
import appreciation from "../Assets/appreciation.png";
import celebration from "../Assets/celebration.png";
import gratitude from "../Assets/gratitude.png";
import gift from "../Assets/gift.png";
import gallery from "../Assets/gallery.png";
import applePay from "../Assets/applePayIcon.png";
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import { loadStripe } from "@stripe/stripe-js";
import { CircularProgress, Snackbar, TextField, useMediaQuery } from "@mui/material";
import { createweb, giveImageReview, giveReview, PIForBoth, USERPROFILECLICK } from "../API/api_endpoints";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { findUserByQR } from "../API/api_endpoints";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../API/urlConstants";
import ReactPlayer from "react-player";
import {  isAndroid } from 'react-device-detect';


const schema = Joi.object({
  sayThanks: Joi.string().trim().max(250).allow("").label("Say Thanks"),
  post: Joi.array().items(Joi.any()).label("Post").default([]),
})
  .or("sayThanks", "post")
  .error((errors) => {
    console.log({errors})
    return new Error('Either "Say Thanks" or "Post" must be provided.');
  });

const reviewTypes = [
  { src: recognition, label: "Recognition", value: "RECOGNITION" },
  { src: appreciation, label: "Appreciation", value: "APPRECIATION" },
  { src: celebration, label: "Celebration", value: "CELEBRATION" },
  { src: gratitude, label: "Gratitude", value: "GRATITUDE" },
];


const Review = ({ review, fullName, email,setGiveGift }) => {
  console.log({ review })
const location = useLocation()
  const navigate = useNavigate()
  const [fees, setFees] = useState({
    card: "3.0%+$0.50",
    bank: "1.00%",
    applePay: "3.0%+$0.50",
  });
  const [isFullscreen, setIsFullscreen] = useState(false);

  const [text,setText] =useState("Note :- Either you can upload 5 images or 1 Video only.")
  const [isPlaying, setIsPlaying] = useState(false);

 
  const [disabledPaymentButton,setDisabledPaymentButton]=useState({"card":false, "us_bank_account":false, "apple_pay":false})

  const isMobile = useMediaQuery("(max-width: 768px)");
  const [sayThanks, setSayThanks] = useState("");
  const [addGift, setAddGift] = useState(false);  
  const [gratitude, setGratitude] = useState(review);
  const [errors, setErrors] = useState({});
  const [post, setPost] = useState([]);
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [amountValidationError, setAmountValidationError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [clientSecret, setClientSecret] = useState(null);
  const [checkout, setCheckout] = useState(false);
  const[inputVal,setInputVal]=useState("")
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState({});
  const [feeAmount, setFeeAmount] = useState("3.0%+$0.50 fee applies");
  const [bankfeeAmount, setBankFeeAmount] = useState("1.00% fee applies");
  const [thumbnail, setThumbnail] = useState("");
  const [load, setLoad] = useState(false);
  const [loader, setLoader] = useState(false);
  const [message,setMessage]=useState({})

  const [loading, setLoading] = useState(true);
  const playerRef = useRef(null); // Reference for the video player
  const videoWrapperRef = useRef(null); // Reference for the video wrapper
const fileInputRef=useRef(null)
const sectionRef = React.useRef(null);


const handleFullscreen = () => {
  if (videoWrapperRef.current) {
    if (videoWrapperRef.current.requestFullscreen) {
      videoWrapperRef.current.requestFullscreen();
    } else if (videoWrapperRef.current.mozRequestFullScreen) { // Firefox
      videoWrapperRef.current.mozRequestFullScreen();
    } else if (videoWrapperRef.current.webkitRequestFullscreen) { // Chrome, Safari, and Opera
      videoWrapperRef.current.webkitRequestFullscreen();
    } else if (videoWrapperRef.current.msRequestFullscreen) { // Internet Explorer/Edge
      videoWrapperRef.current.msRequestFullscreen();
    }
  }
};
const handlePlayButtonClick = () => {
  setIsPlaying(true);
};
  const paymentMethods = [
    {
      src: card,
      label: "Pay via Debit/Credit Card",
      value: "card",
      amount: selectedAmount ? fees.card : "3.0%+$0.50"
    },
    {
      src: bank,
      label: "Pay via Bank",
      value: "us_bank_account",
      amount: selectedAmount ? fees.bank : "1.00%"
    },
    {
      src: applePay,
      label: "Pay via Apple Pay",
      value: "apple_pay",
      amount: selectedAmount ? fees.applePay : "3.0%+$0.50",
    },
  ];


  useEffect(()=>{
if(inputVal){
  setAmountValidationError("");

    // handlePaymentClick(   stripePromise,
    //   selectedAmount,
    //   paymentMethod)
  
}
  },[inputVal])

  const calculateFeesForAllPaymentMethods = (amount) => {
    if (amount) {
      const cardFees = calculateAmountAfterFees(amount);
      const bankFees = calculateBankAmountAfterFees(amount);
      const applePayFees = calculateAmountAfterFees(amount);

      setFees({
        card: `$${cardFees}`,
        bank: `$${bankFees}`,
        applePay: `$${applePayFees}`,
      });
    }
  };

  useEffect(() => {
    calculateFeesForAllPaymentMethods(selectedAmount);
  }, [selectedAmount]);
  useEffect(() => {
    setLoading(true)
    findUserByQR({
      _id: id,
      profilePage: true,
    })
      .then((response) => {
        setUserDetails(response?.data?.data?.userDetails);
      })
      .catch((error) => { });
      setLoading(false)

    const loadStripeAsync = async () => {
      const stripe = await loadStripe(
        "pk_test_51NhHNZFDxlsdZq5eQisMgNxM7vDvVG9SO0Le60jOpQa6KwSxbu9xiP9D8B5oyUQrbn25bu6SBlNhedgMWgSkwaDq00KXlVG4TR"
      );
      setStripePromise(stripe);
    };

    loadStripeAsync();
  }, []);

  const handleGratitudeClick = (item) => {
    setGratitude(item);
    setGiveGift(item)
  };

  const handleGiftClick = () => {
    setAddGift(!addGift);
  };

  const handleSayThanksChange = (e) => {
    setSayThanks(e?.target?.value);
    setErrors((prevErrors) => ({ ...prevErrors, sayThanks: "" }));
  };

  const handleAmountChange = (event) => {
    const inputValue = parseInt(event?.target?.value);
    setInputVal(inputValue)
    console.log({inputValue})
  
    if (!isNaN(inputValue)) {
      setSelectedAmount(inputValue);
      setAmountValidationError(null);
    } else {
      setSelectedAmount(null);
      setAmountValidationError("Please enter a valid amount.");
    }
    setBankFeeAmount(calculateBankAmountAfterFees(parseInt(event?.target?.value)));
    setFeeAmount(calculateAmountAfterFees(parseInt(event?.target?.value)));
  };
  // console.log(inputVal,"ooppp",selectedAmount)

  const calculateAmountAfterFees = (amount) => {
    const stripePercent = 2.9 / 100;
    const stripeFixedFee = 0.3;
    const appPercent = 0.1 / 100;
    const appFixedFee = 0.2;
    const stripeFees = amount * stripePercent + stripeFixedFee;
    const appFees = amount * appPercent + appFixedFee;
    const totalFees = stripeFees + appFees;
    const amountAfterFees = Number(amount) + totalFees;
    return amountAfterFees.toFixed(2);
  };
  const calculatePlatformFee = (amount, feeString, mode) => {
    if (!feeString?.includes("fee")) {
      const numericFeeMatch = feeString.match(/([\d\.]+)/);

      if (numericFeeMatch) {
        const numericFee = parseFloat(numericFeeMatch[1]);
        const fees = numericFee - amount;
        return fees.toFixed(2).toString();
      }
    } else {
      const percentageMatch = feeString.match(/(\d+(\.\d+)?)%/);
      const fixedFeeMatch = feeString.match(/\$([\d\.]+)/);

      const percentage = percentageMatch ? parseFloat(percentageMatch[1]) : 0;
      const fixedFee = fixedFeeMatch ? parseFloat(fixedFeeMatch[1]) : 0;

      const percentageFee = (percentage / 100) * amount;
      const totalFee = percentageFee + fixedFee;

      return totalFee.toFixed(2);
    }
    return "0.00";
  };
  const calculateBankAmountAfterFees = (paymentAmount) => {
    const stripeFeePercentage = 0.8 / 100;
    const appFeePercentage = 0.2 / 100;
    const stripeFees = paymentAmount * stripeFeePercentage;
    const appFees = paymentAmount * appFeePercentage;
    let totalFees = stripeFees + appFees;
    if (totalFees > 10) {
      totalFees = 10;
    }
    const amountAfterFees = Number(paymentAmount) + totalFees;
    return amountAfterFees.toFixed(2);
    
  };
  const handlePaymentClick = async (
    stripePromise,
    selectedAmount,
    paymentMethod
  ) => {
    // alert("pp")
    setLoader(true)
    setAmountValidationError(null);
    const validationResults = schema.validate(
      { sayThanks, post },
      { abortEarly: false }
    );
if(video?.url ===""){
    if (validationResults.error) {
      const newErrors = {};
      validationResults.error.details?.forEach((detail) => {
        newErrors[detail.context.key] = detail.message;
      });

      setErrors(newErrors);
      return;
    }}
    if (selectedAmount !== null && selectedAmount !== undefined) {
      const amountSchema = Joi.number().integer().min(1).required();
      const { error } = amountSchema.validate(selectedAmount);
      if (error) {
        setAmountValidationError("Please enter a valid amount.");
        return;
      }
    }
    console.log({selectedAmount})
    if (!stripePromise || !selectedAmount || !paymentMethod) {

      console.error(
        "Missing required information: amount or card payment method"
      );
      return;
    }
  
    setPaymentMethod(paymentMethod);
    const IdVal = localStorage.getItem("userid");
    
    const formData = new FormData();

    // Append metadata fields
    formData.append('piDescription', 'cardToWallet');
  // Append metadata fields individually
formData.append('metadata[getThanksUserID]', IdVal);
formData.append('metadata[guestName]', fullName);
formData.append('metadata[email]', email);
formData.append('metadata[amountAfterFee]', selectedAmount);
formData.append(
  'metadata[title]',
  userDetails?.businessType === 'NON-PROFIT' ? 'donated to' : 'Tip to'
);
    formData.append(
      'platformFeeForWallet',
      calculatePlatformFee(
        selectedAmount,
        paymentMethod === 'card' || paymentMethod === 'apple_pay' ? fees.card : fees.bank,
        paymentMethod
      )
    );
    formData.append('gratitudeType', 'BOTH');
    formData.append('amount', selectedAmount);
    formData.append('sayThanks', sayThanks);
    formData.append('title', reviewTitle);
    formData.append('icon', review);
    
    // Append additional fields if businessType is NON-PROFIT
    if (userDetails?.businessType === 'NON-PROFIT') {
      formData.append('ngoId', userDetails?._id);
      formData.append('userId', userDetails?._id);
      formData.append('isDonate', true);
      formData.append('isRecurring', false);
    }
    
    // Append media files
    let mediaArray = [];
    if (Array.isArray(post)) {
      post?.forEach((item) => {
        formData.append("media", item);
        mediaArray.push({ name: item.name, type: item.type });
      });
    } else {
      formData.append("media", post);
      formData.append("media", dataURItoBlob(thumbnail), "thumbnail.jpg");

      mediaArray.push(
        { name: post.name, type: post.type },
        { name: "thumbnail.jpg", type: "image/jpeg" }
      );}
    
    // If the payment method is apple_pay, append payment_method_types array
    if (paymentMethod === 'apple_pay') {
      formData.append('payment_method_types[]', 'card');
      formData.append('payment_method_types[]', 'apple_pay');
      formData.append('payment_method_types[]', 'cash_app');
      formData.append('payment_method_types[]', 'us_bank_account');
    } else {
      formData.append('paymentMethodType', paymentMethod);
    }
    
    // You can now use formData for submission
    console.log('FormData prepared:', formData);
    
    // return
    try {
      if(sayThanks==="" && post?.length===0){
        const paymentData = {
          // paymentMethodType: paymentMethod,        // Payment method type (e.g. card, bank)
          piDescription: "cardToWallet",           // Description for the payment intent
          metadata: {
            getThanksUserID: IdVal,                // User ID
            guestName: fullName,                   // Full name of the guest
            email: email,                          // Guest email
            amountAfterFee: selectedAmount,        // The amount after applying any fees
            title: userDetails?.businessType === "NON-PROFIT" ? "donated to" : "Tip to",
          },
          platformFeeForWallet: calculatePlatformFee(
            selectedAmount,
            paymentMethod === "card" || paymentMethod === "apple_pay"
              ? fees.card
              : fees.bank,
            paymentMethod
          ),
          gratitudeType: "BOTH",                   // Gratitude type
          amount: selectedAmount,                  // The amount to be paid
          sayThanks: sayThanks,                    // Whether a thank you message is included
          title: reviewTitle,                      // Title of the review
          icon: review,                            // The review icon
          ...(userDetails?.businessType === "NON-PROFIT" && {
            ngoId: userDetails?._id,
            userId: userDetails?._id,
            isDonate: userDetails?.businessType === "NON-PROFIT"?true:false,
            isRecurring: false,
          })
        };
        
     
        // Handling media files and preparing the media array
        let mediaArray = [];
        if (Array.isArray(post)) {
          post.forEach((item) => {
            mediaArray.push({ name: item.name, type: item.type });  // Pushing each media file to the array
          });
        } else {
          mediaArray.push(
            { name: post.name, type: post.type },                   // Adding single post media
            { name: "thumbnail.jpg", type: "image/jpeg" }           // Adding thumbnail
          );
        }
        
        // Attach media data to the paymentData
        paymentData.media = mediaArray;
        
        // Log or use the paymentData object as needed
        console.log(paymentData, "paymentData");
        
        const formData1 = paymentMethod === "apple_pay"
          ? {
              ...paymentData,
              payment_method_types: [
                "card",
                "apple_pay",
                "cash_app",
                "us_bank_account",
              ],
            }
          : { ...paymentData, paymentMethodType: paymentMethod };
        const response = await fetch(`${API.CREATE_PAYMENT_INTENT}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer guest`,
            "Content-Type": "application/json",   // Set Content-Type header
          },
          body: JSON.stringify(formData1),         // Convert formData to JSON string
        });
        setLoader(false)
        const data = await response.json();
        setClientSecret(data?.data?.paymentIntent);
        setCheckout(true);
      }else{

      
      const response = await fetch(`${API.PIFORBOTH}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer guest`,
          // "Content-Type": "application/json",   // Set Content-Type header
        },
        body: formData,         // Convert formData to JSON string
      });
      setLoader(false)
      const data = await response.json();
      setClientSecret(data?.data?.paymentIntent);
      setCheckout(true);

      console.log(data);                         // Log the response data
    }} catch (error) {
      console.error("Error in API request:", error);
    }}
    

  console.log({message})
const logFormData = (formData) => {
  for (let [key, value] of formData.entries()) {
    console.log({value})
    if (value instanceof File) {
      console.log(`${key}:`);
      console.log(`  Name: ${value.name}`);
      console.log(`  Type: ${value.type}`);
      console.log(`  Size: ${value.size}`);
      console.log(`  Size: ${value}`);

    } else {
      console.log(`${key}: ${value}`);
    }
  }
};
function dataURItoBlob(dataURI) {
  // Split the data URI into the mime type and the base64 part
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // Create an array buffer to store the binary data
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  
  // Write the binary data to the array buffer
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // Create and return a Blob from the array buffer and the MIME type
  return new Blob([ab], { type: mimeString });
}

const handleSubmit = () => {
  setLoad(true); // Set loading state immediately

  setTimeout(() => {
    try {
      const validationResults = schema.validate({ sayThanks, post }, { abortEarly: false });

      if (validationResults?.error && Array.isArray(post)) {
        const newErrors = {};
        validationResults?.error?.details?.forEach((detail) => {
          newErrors[detail?.context?.key] = detail?.message;
        });
        console.log("Validation Errors:", newErrors);
        setErrors(newErrors);
        setLoad(false); // Stop loading state if there's a validation error
        return;
      } else {
        setErrors({});
      }

      let formData = new FormData();
      const IdVal = localStorage.getItem("userid");
      formData.append("isDonate",userDetails?.businessType === "NON-PROFIT"?true:false)
      formData.append('ngoId',IdVal)
      formData.append('userId',IdVal)

      // formData.append('amount',tip)
      formData.append('isRecurring',false)
      formData.append("getThanksUserId", IdVal);
      formData.append("icon", review);
      formData.append("title", reviewTitle);
      formData.append("sayThanks", sayThanks);

      formData.append("gratitudeType", "REVIEW");
      
      let mediaArray = [];
      if (Array.isArray(post)) {
        post?.forEach((item) => {
          formData.append("media", item);
          mediaArray.push({ name: item.name, type: item.type });
        });
      } else {
        formData.append("media", post);
        formData.append("media", dataURItoBlob(thumbnail), "thumbnail.jpg");

        mediaArray.push(
          { name: post.name, type: post.type },
          { name: "thumbnail.jpg", type: "image/jpeg" }
        );
      }

      formData.append("accountType", "Guest");
      formData.append("guestName", fullName);
      formData.append("email", email);
      formData.append("latitude", 0);
      formData.append("longitude", 0);
   
      logFormData(formData);

      if (post?.length) {
        giveImageReview(formData).then((response) => {
          if (response?.status === 200 && IdVal) {
            
            const address = `${API.WEB_STAGING_URL}/${IdVal}?show=true`;
            window.location.href = address;
          }
          setLoad(false); // Stop loading state after submission
        });
      } else {
        
        giveReview(formData).then((response) => {
          if (response?.status === 200 && IdVal) {
            

            const address = `${API.WEB_STAGING_URL}/${IdVal}?show=true`;
            window.location.href = address;
          }
          setLoad(false); // Stop loading state after submission
        });
      }
    } catch (error) {
      console.log({ error });
      setLoad(false); // Stop loading state in case of error
    }
  }, 100); // Adding a slight delay to ensure loading state is set
};


console.log({load})

  const iconValue = (value) => {
    let text = "";
    if (value == "APPRECIATION") {
      text = "appreciated";
    } else if (value == "CELEBRATION") {
      text = "celebrated";
    } else if (value == "RECOGNITION") {
      text = "recognized";
    } else if (value == "GRATITUDE") {
      text = "thanked";
    }
    return text;
  };

  let reviewTitle =
     iconValue(gratitude)
     let titleView=    fullName + " " + iconValue(gratitude) + " " + userDetails?.userName;
     const handleFileSelect = (e) => {
      const files = Array.from(e.target.files);
      if (!files.length) return;
      setErrors({}); // Clear previous errors
      // Filter out valid image files (jpeg, jpg, png)
      const validImageFiles = files.filter(
        (file) =>
          file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/png" ||
          file.type === "image/webp" 

      );
      // Validate image size (25KB to 300KB)
      const minSizePhoto = 25 * 1024; // 25KB
      const maxSizePhoto = 5 * 1024 * 1024; // 5MB
      const validSizeFiles = validImageFiles.filter(
        (file) => file.size >= minSizePhoto && file.size <= maxSizePhoto
      );
      // Collect files that are too large or too small (these won't be uploaded)
      const invalidSizeFiles = validImageFiles.filter(
        (file) => file.size < minSizePhoto || file.size > maxSizePhoto
      );
      console.log(  files.type,"  file.type")

      // Check for unsupported files (not images or videos)
      const hasUnsupportedFiles = files.some(
        
        (file) =>
          !(
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png" ||
            file.type === "video/quicktime" ||
            file.type === "video/mp4" ||
            file.type === "video/mov" ||
            file.type === "video/heif"
           
          )
          
      );
      if (hasUnsupportedFiles) {
        setErrors({ fileSelect: "Unsupported file format." });
      }
      // Handle video files separately
      if (
        files[0]?.type === "video/quicktime" ||
        files[0]?.type === "video/mp4"
      ) {
        if (!video) {
          setImages([]); // Clear images if a video is selected
          handleVideoFile(files[0]);
        } else {
          setErrors({ fileSelect: "Only one video allowed." });
          setText("")
        }
      } else {
        // Calculate how many more images can be added
        const remainingSlots = 5 - images.length;
        // Limit the number of valid images to add based on remaining slots
        const imagesToAdd = validSizeFiles.slice(0, remainingSlots);
        if (validSizeFiles.length > remainingSlots) {
          setErrors({ fileSelect: "You can only upload up to 5 images" });
          setText("")
        }
        // If there are images that do not meet size requirements, show an error
        if (invalidSizeFiles.length > 0) {
          setErrors({
            fileSelect:
              "Some images were not uploaded due to size restrictions (25KB to 5mb).",
          });
          setText("")
        } else if (validSizeFiles.length <= remainingSlots) {
          setErrors({}); // Clear errors if all files are valid
        }
        // Upload only the valid image files
        handleImageFiles(imagesToAdd);
        setVideo(null);
      }
      // Reset the file input value to allow re-uploading the same file
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    };
  
   
    const handleImageFiles = (files) => {
      files.forEach((file) => {
        setImages((prevImages) => [
          ...prevImages,
          { url: URL.createObjectURL(file), type: file.type },
        ]);
      });
      console.log(post, "post")
     !video && setPost((prevPost) => Array.isArray(post) && post.length? [...prevPost, ...files]: [...files]);
    };
  
    











  
  const resizeAndCompressThumbnail = (canvas, maxWidth, maxHeight, quality = 0.7) => {
    const ctx = canvas.getContext("2d");
    const scale = Math.min(maxWidth / canvas.width, maxHeight / canvas.height);
  
    const newWidth = canvas.width * scale;
    const newHeight = canvas.height * scale;
  
    const resizedCanvas = document.createElement("canvas");
    resizedCanvas.width = newWidth;
    resizedCanvas.height = newHeight;
  
    const resizedCtx = resizedCanvas.getContext("2d");
    resizedCtx.drawImage(canvas, 0, 0, newWidth, newHeight);
  
    // Compress the image by setting a lower quality (0.7 for JPEG)
    return resizedCanvas.toDataURL("image/jpeg", quality);
  };
  
  const handleVideoFile = (file) => {
    const maxSizeVideo = 50 * 1024 * 1024; // 50 MB
    const maxVideoDuration = 30; // 30 seconds
  
    if (file.size <= maxSizeVideo) {
      const video = document.createElement("video");
      video.preload = "metadata";
  
      video.onloadedmetadata = function () {
        if (video.duration <= maxVideoDuration) {
          setVideo({ url: URL.createObjectURL(file), type: "video" });
  
          video.currentTime = 0;
  
          video.onseeked = function () {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
  
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
  
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  
            // Resize and compress the thumbnail
            const compressedThumbnailDataUrl = resizeAndCompressThumbnail(canvas, 300, 300, 0.7);
  
            // Set the thumbnail URL
            setThumbnail(compressedThumbnailDataUrl);
  
            // Clean up video URL object
            URL.revokeObjectURL(video.src);
  
            // Set the file for post or upload
            setPost(file);
  
            console.log({ thumbnail: compressedThumbnailDataUrl });
          };
        } else {
          setErrors({
            fileSelect: "The video must be under 30 seconds and less than 50 MB. You can either upload up to 5 images or 1 video.",
          });
          setText("")
        }
      };
  
      video.src = URL.createObjectURL(file);
    } else {
      setErrors({ fileSelect: "The video must be under 30 seconds and less than 50 MB. You can either upload up to 5 images or 1 video." });
      setText("")

    }
  };
  
  
  

  const handleCancelImage = (index) => {
    console.log(post, "post");
  
    const newImages = [...images];
    newImages.splice(index, 1);
    if (newImages.length < 5) {
      setErrors({});
    }
    setImages(newImages);
  
    // Ensure post is an array before modifying it
    if (Array.isArray(post)) {
      const newPost = [...post];
      newPost.splice(index, 1);
      setPost(newPost);
    } else {
      console.error("Error: 'post' is not an array");
      setPost([]); // Reset to an empty array or handle as needed
    }
  };
  
  const handleCancelVideo = () => {
    setPost([]);
    setErrors({});
    setVideo(null);
  };

  console.log({ video }, post)

  useEffect(() => {
    const fetchData = async () => {

      const formData = {
        email: email,
        fullName: fullName

      };

      try {
       
        const response = createweb(formData).then((response) =>
          // console.log(response?.data?.userId, "data")
    {

      console.log(response?.data?.userId, "data")
      if(response?.data?.userId){
        const IdVal = localStorage.getItem("userid");

        const formDataVal = {
          userProfileId: IdVal,
          profileClickBy:response?.data?.userId,
         guestName:fullName
        };
        try {
          const response = USERPROFILECLICK(formDataVal).then((response) =>
            console.log(response, "data")
      
          );
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          // const data = await response.json();
          // console.log('Payment Intent created successfully:', data);
          // Handle the response data here
  
        } catch (error) {
          console.error('Error creating Payment Intent:', error);
          // Handle any errors here
        }
      }
    }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Payment Intent created successfully:', data);
        // Handle the response data here
     
      } catch (error) {
        console.error('Error creating Payment Intent:', error);
        // Handle any errors here
      }
   
    };

    fetchData(); // Call the async function
  }, [navigate])


  const handleScroll = () => {

    setTimeout(() => {
      if (sectionRef.current) {
        sectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 2000); //
  };






//fullscreen code for video player

const exitFullscreen = () => {
  if (document.fullscreenElement) {
    document.exitFullscreen();
  }
};

useEffect(() => {
  const handleFullscreenChange = () => {
    setIsFullscreen(true);
    if (document.fullscreenElement) {
      // Entered fullscreen
      setIsFullscreen(true);
    } else {
      // Exited fullscreen
      setIsFullscreen(false);
      setIsPlaying(false); // Stop playing when exiting fullscreen
    }
  };

  // Add event listeners for fullscreen change
  document.addEventListener('fullscreenchange', handleFullscreenChange);
  document.addEventListener('webkitfullscreenchange', handleFullscreenChange); // For older browsers

  // Clean up event listeners on component unmount
  return () => {
    document.removeEventListener('fullscreenchange', handleFullscreenChange);
    document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
  };
}, []);
const handleEnd=()=>{
  setIsPlaying(false);

}
// useEffect(() => {
//   const handleFullscreenChange = () => {
//     if (!document.fullscreenElement) {
//       // Exit from fullscreen detected
//       console.log('Exited fullscreen, stopping the video');
//       setIsPlaying(false); // Set isPlaying to false when exiting fullscreen
//     }
//   };

//   // Add event listener for fullscreen change
//   document.addEventListener('fullscreenchange', handleFullscreenChange);

//   // Clean up the event listener on component unmount
//   return () => {
//     document.removeEventListener('fullscreenchange', handleFullscreenChange);
//   };
// }, []);
  return (
    <div className="w-full flex flex-col text-center  pb-8 gap-4 ">
      <div className="flex flex-col gap-5">
        <div
        style={{
          position:"relative",zIndex:"9999"
        }}
          className={`flex    ${isMobile ? "gap-2 justify-center" : "gap-1 justify-between"
            }`}>
          {reviewTypes.map((items) => {
            return (
              <button
                key={items?.value}
                className={`flex flex-col border border-[#979797] h-[5rem] w-[5.4rem] rounded-[0.625rem] justify-end gap-0.5 py-3 border-opacity-[30%] items-center ${items.value === gratitude
                  ? "bg-gradient-to-br from-[#FF8245] to-[#524CBB]"
                  : ""
                  }`}
                onClick={() => handleGratitudeClick(items.value)}
                value={items.value}>
                <img src={items.src} alt="" className="w-[2rem] h-[1.5rem]" />
                <p
                  className={`${items.value === gratitude ? "text-white font-medium text-xs hover:cursor-pointer" : "text-[#1D1546]"
                    } font-medium text-xs hover:cursor-pointer`}>
                  {items.label}
                </p>
              </button>
            );
          })}
        </div>
      </div>
      <TextField
        id="review-title"
        label="Review Title"
        type="text"
        required
        value={userDetails?.userName !==undefined ?titleView:""}
        disabled
        sx={{
          "& .MuiInputLabel-root": {
            fontFamily: "Plus Jakarta Sans",
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderRadius: "10px",
          },
        }}
      />
      <div className="relative w-full">
        <TextField
          id="Say thanks" // ID for the TextField
          label="Say thanks" // Label for the input field
          placeholder="If you'd like, use this space to give some words of thanks." // Fixed placeholder
          type="text"
          multiline
          rows={4}
          maxLength={250}
          inputProps={{
            maxLength: 250,
          }}
          required
          value={sayThanks}
          onChange={handleSayThanksChange}
          error={!!errors.sayThanks}
          helperText={errors.sayThanks}
          sx={{
            width: "100%",
            "& .MuiInputLabel-root": {
              fontFamily: "Plus Jakarta Sans",
            },
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderRadius: "10px",
            },
          }}
        />
        <img
          src={gallery}
          alt=""
          className={`absolute  right-[8px]  w-[25px] h-[25px] cursor-pointer  ${errors?.sayThanks ? "bottom-[30px]" : "bottom-[8px]"
            }`}
          onClick={() => {
            document.getElementById("add-post").click();
          }}
        />
        <input
        maxLength={5}
          type="file"
          accept="image/*,video/*"
ref={fileInputRef}
          // accept="image/jpeg, image/jpg, image/png, video/mp4, video/mov, video/heic, video/heif,.mov,.heic"
          id="add-post"
          style={{ display: "none" }}
          multiple={true}
          onChange={handleFileSelect}
          className="absolute bottom-[8px] right-[8px]  w-[25px] h-[25px] cursor-pointer"
          {...(images?.length < 5 ? { multiple: true } : {})}
        />

      </div>
      {errors.fileSelect && (
        <p className="text-[#d32f2f] font-light text-xs mt-[3px] mr-[14px] text-left">
          {errors.fileSelect}
        </p>
      )}
            {text && (

<div className="flex  text-left pb-0">
  <p className="text-orange-500 text-xs">{text}</p>
</div>)}
     
     {images && images.length > 0 &&(
      <div className="flex flex-wrap gap-4">
        {images?.map((image, index) => (
          <div key={index} className="w-[100px] h-[100px] rounded-xl relative">
            <img
              src={image.url}
              alt=""
              className="h-full w-full object-cover rounded-xl"
            />
            <DeleteIcon
className="absolute top-[-9px] text-red-500 right-[-9px] text-[#979797] cursor-pointer z-[10000]"
onClick={() => handleCancelImage(index)}
            />
          </div>
        ))}
      </div>)}
      {video && video?.url&& (
        <div className="w-[100px] h-[100px] rounded-xl relative">
        
            <div className="video-player-wrapper" style={{width:"100px",height:"100px"}}      ref={videoWrapperRef}
        onClick={()=>{handlePlayButtonClick();
          handleFullscreen(); // Open in full screen on click
        }
        }>
<div className="video-container">
<ReactPlayer
          controls={isFullscreen ?true:false}  // Disable default controls to hide three dots and all controls

                ref={playerRef}
        url={video.url}
        playing={isPlaying}
          width="100%" 
          height="100%"
          objectFit="cover"
          onPause={() => setIsPlaying(false)} // Update isPlaying when the video is paused
          onEnded={() => handleEnd()} // Update isPlaying when the video ends
        /></div>
        {!isPlaying && (
          <div className="play-button-overlay" onClick={() => setIsPlaying(true)}>
            <button className="plays-button"><span className="absolute top-[45%] left-[55%] transform -translate-x-2/4 -translate-y-2/4">▶</span></button>
          </div>
        )}
         {isFullscreen && (
        <button className="fullscreen-exit-button" onClick={exitFullscreen}>
          ✖ {/* Custom cross icon */}
        </button>
      

        
      )}
        
        </div>
          <DeleteIcon
            className="absolute top-[-9px] right-[-9px] text-[20px] text-red-500 z-[10000] cursor-pointer "
            onClick={handleCancelVideo}
          />
        </div>
      )}

      {addGift && (
        <div className="flex flex-col gap-1">
          <input
          onKeyPress={(e) => {
            if (e.key === '.' || e.key === ',' || e.key === 'e') {
              e.preventDefault(); // Prevent the dot (.) and other invalid characters
            }
          }}
          onPaste={(e) => {
            const pastedData = e.clipboardData.getData('text');
            if (pastedData.includes('.') || pastedData.includes(',') || isNaN(pastedData)) {
              e.preventDefault(); // Prevent pasting if it contains a dot or non-numeric data
            }
          }}
            type="number"
            id="amount"
            min={0}
            step={1}
            className={`w-full border-[1px] border-[#979797] rounded-[0.625rem] border-opacity-[30%] font-light h-[3.125rem] text-base p-[0.625rem] ${amountValidationError ? "border-red-500" : ""
              }`}
            placeholder="Enter amount (in Dollars)"
            value={selectedAmount || ""}
            onChange={handleAmountChange}
          />
          {amountValidationError && (
            <p className="w-full self-start  text-red-500 text-sm text-left">
              {amountValidationError}
            </p>
          )}

          {loader ? null:
          <div className="flex flex-col gap-2 mt-6">
      {paymentMethods.filter((item) => !((isAndroid) && item.value === "apple_pay")).map((items) => {
              return (
                <>
              <button
              className={`w-full border-[1px] border-[#979797] rounded-[0.625rem] border-opacity-[30%] h-[3.125rem] flex items-center gap-2 p-[0.625rem] cursor-pointer ${
                selectedAmount && disabledPaymentButton[items.value]
                  ? "bg-[#e0e0e0] border-[#000000]"
                  : "bg-white"
              }`}
              disabled={selectedAmount && disabledPaymentButton[items.value]}
              onClick={() => {
                handleScroll()

                if (selectedAmount) {
                  setPaymentMethod(items?.value);
                  handlePaymentClick(stripePromise, selectedAmount, items?.value);
                  setDisabledPaymentButton({
                    card: items.value === "card",
                    us_bank_account: items.value === "us_bank_account",
                    apple_pay: items.value === "apple_pay",
                  });
                } else {
                  setAmountValidationError(
                    "Please enter an amount before proceeding"
                  );

                }
              }}
            >
              <img src={items.src} alt="" className="w-[1.5rem] h-[1.5rem]" />
              <p className="text-[#1D1546] font-medium text-sm">
                {items.label}
              </p>
            </button>
                  {/* {selectedAmount && */}
                  <p className="w-full self-start  text-green-700 text-sm text-left">
                    {`You will be charged ${items?.amount}`}

                  </p>

                </>
              );
            })}</div>}
        </div>
      )}

      {loader ?    <div>      <CircularProgress sx={{color:"rgb(255 111 41)",width:"30px !important",height:"30px !important "}}/></div>
:
      <div ref={sectionRef}>
      {stripePromise && clientSecret && checkout ? (
        <Elements
          stripe={stripePromise}
          key={clientSecret}
          options={{ clientSecret }}>
          <CheckoutForm msg={message} amount={selectedAmount}/>
        </Elements>
      ) : null}</div>}
 {message?.fileSelect && (
        <p className="text-[#d32f2f] font-light text-xs mt-[3px] mr-[14px] ml-[14px] text-left">
          {message}
        </p>
      )}
      {!addGift &&
        <button
          type="button"
          className="flex flex justify-center gap-3 border border-[#FF6F29] w-full h-[3.75rem]  rounded-[0.625rem] items-center"
          onClick={handleGiftClick}>
          <img src={gift} alt="" className="w-[1.313] h-[1.625rem]" />
          {/* <p className="text-[#FF6F29] font-normal text-sm ">Add Gift</p> */}

          
          {
          ( userDetails?.businessType === "NON-PROFIT" ) ?<p className="text-[#FF6F29] font-normal text-sm">Add Donation</p> :userDetails?.businessType!==undefined? <p className="text-[#FF6F29] font-normal text-sm">Add Gift</p>:""}   

        </button>}

      {!selectedAmount && (
        load?
        
        <div>
          <CircularProgress sx={{color:"rgb(255 111 41)",width:"30px",height:"30px "}}/>
          </div>:
        <button
          className={`flex justify-center items-center w-full h-[3.75rem] rounded-[0.625rem] 
          ${(sayThanks !== "" || images?.length >0 || video) ? "bg-[#FF6F29] cursor-pointer" : "bg-[lightgrey] cursor-not-allowed"}`}

          onClick={(sayThanks !== "" || images?.length >0 || video) ?  handleSubmit:null}
          disabled={(sayThanks !== "" || images?.length >0 || video)?false:true}
        >
          <p className="text-white font-bold text-lg">Submit</p>
        </button>
      )}



    </div>

  );
};

export default Review;
