import React, { useEffect, useRef, useState } from "react";
import recognition from "../../Assets/recognition.png";
import User from "../../Assets/user.png";
import ImageSlider from "../../components/imageDisplay";
import appreciation from "../../Assets/appreciation.png";
import celebration from "../../Assets/celebration.png";
import gratitude from "../../Assets/gratitude.png";
import noData from "../../Assets/Frame.png";
import { text_formatter } from "../../utils";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ShareIcon from "@mui/icons-material/Share";
import CommentIcon from "@mui/icons-material/Comment";
import { Snackbar } from "@mui/material";
import profile from "../../Assets/user_profile.jpg";
import ReactPlayer from 'react-player';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
const reviewTypes = [
  { src: recognition, label: "Recognize", value: "RECOGNITION" },
  { src: appreciation, label: "Appreciate", value: "APPRECIATION" },
  { src: celebration, label: "Celebrate", value: "CELEBRATION" },
  { src: gratitude, label: "Gratitude", value: "GRATITUDE" },
];
const iconActions = [
  { name: "Like", icon: <ThumbUpIcon style={{ fontSize: ".9rem" }} /> },
  { name: "Comment", icon: <CommentIcon style={{ fontSize: ".9rem" }} /> },
  { name: "Share", icon: <ShareIcon style={{ fontSize: ".9rem" }} /> },
];
const Feeds = ({ feedDetails }) => {
  const [isPlaying, setIsPlaying] = useState(-1);

  const handlePlayButtonClick = (index) => {
    playerRef.current= ""
    setIsPlaying(index);
  };
  const [socialName, setSocialName] = useState("");
  const [cardIndex, setCardIndex] = useState(-1);
  const [open, setOpen]=useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false);

  const playerRef = useRef(null); // Reference for the video player
  const videoWrapperRef = useRef([]); // Reference for the video wrapper

  const handleEnd=()=>{
    // alert("oo")
      console.log('Video ended');
      setIsPlaying(-1);
    
  }
  
  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        // Exit from fullscreen detected
        console.log('Exited fullscreen, stopping the video');
        setIsPlaying(-1); // Set isPlaying to false when exiting fullscreen
      }
    };
  
    // Add event listener for fullscreen change
    document.addEventListener('fullscreenchange', handleFullscreenChange);
  
    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);
  
  
  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  };
  
  console.log({isFullscreen})
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(true);
      if (document.fullscreenElement) {
        // Entered fullscreen
        setIsFullscreen(true);
      } else {
        // Exited fullscreen
        setIsFullscreen(false);
        setIsPlaying(-1); // Stop playing when exiting fullscreen
      }
    };
  
    // Add event listeners for fullscreen change
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange); // For older browsers
  
    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
    };
  }, []);
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const currentDate = new Date();
    const diff = currentDate - date;
    const minute = 60 * 1000;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;

    if (diff < minute) {
      return "Just now";
    } else if (diff < hour) {
      const minutes = Math.floor(diff / minute);
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else if (diff < day) {
      const hours = Math.floor(diff / hour);
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (diff < month) {
      const days = Math.floor(diff / day);
      if (days <= 1) {
        return `Yesterday  |  ${date.toLocaleTimeString()}`;
      } else {
        return `${date.getDate()} ${getMonthName(
          date.getMonth()
        )}  |  ${date.toLocaleTimeString()}`;
      }
    } else {
      const months = Math.floor(diff / month);
      return `${months} month${months > 1 ? "s" : ""} ago`;
    }
  };

  const getMonthName = (month) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[month];
  };

  const reviewIcon = (review) => {
    const matchingType = reviewTypes.find((item) => item.value === review);
    const iconSrc = matchingType ? matchingType.src : "";
    console.log({iconSrc},review)
    return iconSrc ? (
      <img src={iconSrc} alt={review} className="h-[2rem]" />
    ) : (
      <img
        src={User}
        alt="User"
        className="h-[2rem] border-2 border-white p-1 rounded-full"
      />
    );
  };
  if (!feedDetails?.length ||     (feedDetails.length===1 && feedDetails[0]?.gratitudeType === "TIP")) {
    return <div style={{display:"flex",flexDirection:"column"}}><img src={noData} alt="No feeds" className="rounded-lg" /> <p style={{fontSize: "16px",fontFamily: "PlusJakartaSans-Medium",
      color: "#B9B9B9"}}>This is where the timeline of the</p><p style={{fontSize: "16px",fontFamily: "PlusJakartaSans-Medium",
        color: "#B9B9B9"}}>thanks you receive will appear</p></div> 
  }

  const socialClickHandler = (name, value) => {
    setSocialName(name);
    setCardIndex(value);
  };

  const handleFullscreen = (index) => {
    const videoElement = videoWrapperRef.current[index];

    if (videoElement) {
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.mozRequestFullScreen) { // Firefox
        videoElement.mozRequestFullScreen();
      } else if (videoElement.webkitRequestFullscreen) { // Chrome, Safari, and Opera
        videoElement.webkitRequestFullscreen();
      } else if (videoElement.msRequestFullscreen) { // Internet Explorer/Edge
        videoElement.msRequestFullscreen();
      }
    }
  };
  return feedDetails.map((feed, index) => (
  <> {
    feed?.gratitudeType === "REVIEW" && feed?.sayThanks !=="" ||feed?.media?.length?   <div
    key={feed?.id}
   className="h-fit w-full py-[10px]  rounded-[0.625rem] bg-white text-[#1D1546] shadow-md shadow-[#FFEFE8]"
    style={{
      border: index === cardIndex  ? "1px solid #FF6F29" : feed?.boosted?"2px solid black":"1px solid #FFEFE8",
    }}
  >
      <div
      className={`flex flex-col px-[1rem]  ${
        feed?.gratitudeType === "REVIEW" ? "gap-[1rem]" : ""
      }`}
    >
      <div className="flex justify-between gap-16 items-center">
        <div className={`flex items-center ${feed?.gratitudeType === "REVIEW"? "gap-3":"gap-[.15rem]"}`}>
        {feed?.gratitudeType === "REVIEW" ? reviewIcon(feed?.icon) : <span className="text-base">💁‍♀️</span>}
          <p className="text-inherit font-semibold text-sm">
            {text_formatter(feed?.title)}
          </p>
        </div>
        <div className="w-[2rem] h-[2rem] " onClick={()=>setOpen(true)}>
       <img
          src={feed?.giveThanksUser?.profilePicture || profile}
          alt=""
          className="w-[100%] h-[100%] rounded-[100%] cursor-pointer"
        />
      
       </div>
      </div>
      {feed?.media?.length ===0 && feed?.sayThanks ===""&&(
   <div style={{display:"flex",flexDirection:"column"}}><img src={noData} alt="No feeds" className="rounded-lg" /> <p style={{fontSize: "16px",fontFamily: "PlusJakartaSans-Medium",
    color: "#B9B9B9"}}>This is where the timeline of the</p><p style={{fontSize: "16px",fontFamily: "PlusJakartaSans-Medium",
      color: "#B9B9B9"}}>thanks you receive will appear</p></div> 
)}
      {feed?.media[0]?.mimeType?.startsWith("image") && (
        <ImageSlider images={feed?.media} />
      )}
         {feed?.media[0]?.mimeType?.startsWith("video") && (
     
        <div className="video-player-wrapper"       
        ref={(el) => (videoWrapperRef.current[index] = el)}
        onClick={()=>{handlePlayButtonClick(index);
          handleFullscreen(index); // Open in full screen on click
        }
        }>
          <div className="video-container">

        <ReactPlayer 
        
               
                controls={isFullscreen ?true:false}  // Disable default controls to hide three dots and all controls
                url={feed?.media[0].imgUrl}
                playing={isPlaying === index}
          // controls
          width="100%" 
          height="100%"
          objectFit="cover"

          onPause={() => setIsPlaying(-1)} // Update isPlaying when the video is paused
          onEnded={() => handleEnd()} // Update isPlaying when the video ends
        /></div>
        {!isFullscreen && (
          <div className="play-button-overlay" >
            <button className="play-button">▶</button>
          </div>
        )}


         {isFullscreen && (
        <button className="fullscreen-exit-button" onClick={exitFullscreen}>
          ✖ {/* Custom cross icon */}
        </button>
      )}
      </div>
        
      )} 

      {feed?.gratitudeType==="TIP" && (
        <div className="text-[2rem] font-extrabold text-inherit">
          ${feed?.tipAmount}
        </div>
      )}


     { feed?.gratitudeType==="TIP" &&  <div className="text-sm text-inherit mb-[0.2rem]">Monetary Donations</div>}

      {feed?.sayThanks && (
        <div>
          <p className="text-inherit font-normal text-sm">
            {feed?.sayThanks}
          </p>
        </div>
      )}

      <div className="flex gap-2 justify-between ">
        <p
          className={`text-[#979797] font-medium text-xs mb-[.5rem] ${
            feed?.gratitudeType === "REVIEW" ? "text-#979797" : "text-white"
          }`}
        >
          {formatDate(feed?.createdAt)}
        </p>
        <div className="flex items-center ">
  {feed?.boosted && (
    <>
    <ElectricBoltIcon className="h-[16px] text-indigo-600" style={{height:"16px"}}/>
    <p className={`text-indigo-600 text-sm ${feed?.boosted}`}>
    Boosted
  </p></>
  )}
 
</div>

      </div>
    </div>
  {   feed?.gratitudeType === "REVIEW" ? <div
      className={`flex justify-between border-t pt-2  ${
        feed?.gratitudeType === "REVIEW"
          ? "bg-white border-[#979797] border-opacity-[10%] "
          : "bg-[#57a660] border-[#fff]"
      }`}
    >
      {iconActions?.map((item, innerIndex) => (
        <div
          key={innerIndex}
          onClick={() => {
            socialClickHandler(item.name, index);
          }}
          className={`flex items-center  px-[1rem] cursor-pointer gap-[5px] justify-center align-center ${
            feed?.gratitudeType === "REVIEW" ? "text-[#979797]" : "text-white"
          }`}
        >
          <span
            className={`font-medium text-xs ${
              feed?.gratitudeType === "REVIEW"
                ? "text-[#979797]"
                : "text-white"
            }`}
          >
            {" "}
            {item.icon}
          </span>
          <span
            className={`font-medium text-xs ${
              feed?.gratitudeType === "REVIEW"
                ? "text-[#979797]"
                : "text-white"
            }`}
          >
            {item.name}
          </span>
        </div>
      ))}
    </div>:null}
 
    <Snackbar
      open={socialName || open}
      autoHideDuration={1000}
      onClose={() => {
        setSocialName("");
        setCardIndex(-1);
        setOpen(false)
      }}
      message="Download app to avail this feature"
      sx={{ background: "#000", color: "#fff" }}
    />
  </div>:null


  }
  
 
  
  </>
  ));
};

export default Feeds;
