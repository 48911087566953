import React, { useState, useEffect, useRef } from "react";
import bank from "../Assets/bank.png";
import card from "../Assets/card.png";
import applePay from "../Assets/applePayIcon.png";
import { loadStripe } from "@stripe/stripe-js";
import Joi from "joi";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { createweb, USERPROFILECLICK } from "../API/api_endpoints";
import API from "../API/urlConstants";
import { CircularProgress } from "@mui/material";
import {  isAndroid } from 'react-device-detect';

const amountOptions = [10, 15, 20];

const PaymentOptions = ({ fullName, email, userDetails }) => {
  const navigate = useNavigate();
  const [stripePromise, setStripePromise] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [amountValidationError, setAmountValidationError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [clientSecret, setClientSecret] = useState(null);
  const [checkout, setCheckout] = useState(false);
  const [loader, setLoader] = useState(false);

  const [disabledPaymentButton,setDisabledPaymentButton]=useState({"card":false, "us_bank_account":false, "apple_pay":false})
  const [fees, setFees] = useState({
    card: "3.0%+$0.50",
    bank: "1.00%",
    applePay: "3.0%+$0.50",
  });

  useEffect(() => {
    const loadStripeAsync = async () => {
      const stripe = await loadStripe(
        "pk_test_51NhHNZFDxlsdZq5eQisMgNxM7vDvVG9SO0Le60jOpQa6KwSxbu9xiP9D8B5oyUQrbn25bu6SBlNhedgMWgSkwaDq00KXlVG4TR"
      );
      setStripePromise(stripe);
    };

    loadStripeAsync();
  }, []);

  const calculateFeesForAllPaymentMethods = (amount) => {
    if (amount) {
      const cardFees = calculateAmountAfterFees(amount);
      const bankFees = calculateBankAmountAfterFees(amount);
      const applePayFees = calculateAmountAfterFees(amount);

      setFees({
        card: `$${cardFees}`,
        bank: `$${bankFees}`,
        applePay: `$${applePayFees}`,
      });
    }
  };

  useEffect(() => {
    calculateFeesForAllPaymentMethods(selectedAmount);
  }, [selectedAmount]);

  const handleAmountChange = (event) => {
    const inputValue = parseInt(event?.target?.value);
    if (!isNaN(inputValue)) {
      setSelectedAmount(inputValue);
      setAmountValidationError(null);
    } else {
      setSelectedAmount(null);
      setAmountValidationError("Please enter a valid amount.");

    }
    setCheckout(false);
    setDisabledPaymentButton({"card":false, "us_bank_account":false, "apple_pay":false})
  };

  const handleAmountButtonClick = (amount) => {
    setSelectedAmount(amount);
    setAmountValidationError(null);
  };

  const calculatePlatformFee = (amount, feeString, mode) => {
    if (!feeString?.includes("fee")) {
      const numericFeeMatch = feeString.match(/([\d\.]+)/);

      if (numericFeeMatch) {
        const numericFee = parseFloat(numericFeeMatch[1]);
        const fees = numericFee - amount;
        return fees.toFixed(2).toString();
      }
    } else {
      const percentageMatch = feeString.match(/(\d+(\.\d+)?)%/);
      const fixedFeeMatch = feeString.match(/\$([\d\.]+)/);

      const percentage = percentageMatch ? parseFloat(percentageMatch[1]) : 0;
      const fixedFee = fixedFeeMatch ? parseFloat(fixedFeeMatch[1]) : 0;

      const percentageFee = (percentage / 100) * amount;
      const totalFee = percentageFee + fixedFee;

      return totalFee.toFixed(2);
    }
    return "0.00";
  };

  function calculateAmountAfterFees(amount) {
    const stripePercent = 2.9 / 100;
    const stripeFixedFee = 0.3;
    const appPercent = 0.1 / 100;
    const appFixedFee = 0.2;
    // Calculate Stripe fees
    const stripeFees = amount * stripePercent + stripeFixedFee;
    // Calculate app fees
    const appFees = amount * appPercent + appFixedFee;
    // Calculate the total fees
    const totalFees = stripeFees + appFees;
    const amountAfterFees = Number(amount) + totalFees;
    return amountAfterFees.toFixed(1);
  }

  const calculateBankAmountAfterFees = (paymentAmount) => {
    const stripeFeePercentage = 0.8 / 100;
    const appFeePercentage = 0.2 / 100;

    const stripeFees = paymentAmount * stripeFeePercentage;
    const appFees = paymentAmount * appFeePercentage;
    let totalFees = stripeFees + appFees;

    if (totalFees > 10) {
      totalFees = 10;
    }
    const amountAfterFees = Number(paymentAmount) + totalFees;
    return amountAfterFees.toFixed(1);
  };

  const handlePaymentClick = async (
    stripePromise,
    selectedAmount,
    paymentMethod
  ) => {
    setLoader(true)
    const amountSchema = Joi.number().integer().min(0).required();
    const { error } = amountSchema.validate(selectedAmount);

    if (error) {
      setAmountValidationError("Please enter a valid amount.");
      return;
    }

    if (!stripePromise || !selectedAmount || !paymentMethod) {
      console.error(
        "Missing required information: amount or card payment method"
      );
      return;
    }

    let reviewTitle = fullName + " " + "Tip to" + " " + userDetails?.fullName;

    const IdVal = localStorage.getItem("userid");
    const paymentData = {
      piDescription: "cardToWallet",
      metadata: {
        getThanksUserID: IdVal,
        title: userDetails?.businessType === "NON-PROFIT" ?"donated to":"Tip to",
        guestName: fullName,
        email: email,
        amountAfterFee:
          selectedAmount 
      },
      platformFeeForWallet: calculatePlatformFee(
        selectedAmount,
        paymentMethod === "card" || paymentMethod === "apple_pay"
          ? fees.card
          : fees.bank,
        paymentMethod
      ),
      amount: selectedAmount,

      ...(userDetails?.businessType === "NON-PROFIT" &&{
        userId: IdVal,
        ngoId: IdVal,
        isRecurring:false,
      })
    
    };

    const formData =
      paymentMethod === "apple_pay"
        ? {
            ...paymentData,
            payment_method_types: [
              "apple_pay",
            ],
          }
        : { ...paymentData, paymentMethodType: paymentMethod };
if(userDetails?.businessType === "NON-PROFIT"){
  const response = await fetch(API.CREATE_DONATION_INTENT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer guest",
    },
    body: JSON.stringify(formData),
  });
  setLoader(false)
  const data = await response.json();
  setClientSecret(data?.data?.paymentIntent);
  setCheckout(true);
  navigate(`/user_profile/${IdVal}`);
}else{
  const response = await fetch(API.CREATE_PAYMENT_INTENT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer guest",
    },
    body: JSON.stringify(formData),
  });
  setLoader(false)

  const data = await response.json();
  setClientSecret(data?.data?.paymentIntent);
  setCheckout(true);
  navigate(`/user_profile/${IdVal}`);
}
  
  };

  useEffect(() => {
    const fetchData = async () => {
      const formData = {
        email: email,
        fullName: fullName,
      };

      try {
        const response = await createweb(formData);
        console.log(response?.data, "data");
        if(response?.data?.userId){
          const IdVal = localStorage.getItem("userid");
  
          const formDataVal = {
            userProfileId: IdVal,
            profileClickBy:response?.data?.userId,
           guestName:fullName
          };
          try {
            const response = USERPROFILECLICK(formDataVal).then((response) =>
              console.log(response, "data")
        
            );
    
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            // const data = await response.json();
            // console.log('Payment Intent created successfully:', data);
            // Handle the response data here
    
          } catch (error) {
            console.error('Error creating Payment Intent:', error);
            // Handle any errors here
          }
        }
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Payment Intent created successfully:", data);
      } catch (error) {
        console.error("Error creating Payment Intent:", error);
        // Handle any errors here
      }
    };

    fetchData();
  }, [navigate]);

  // Define payment methods here
  const paymentMethods = [
    {
      src: card,
      label: "Pay via Debit/Credit Card",
      value: "card",
      amount: selectedAmount ? fees.card : "3.0%+$0.50",
    },
    {
      src: bank,
      label: "Pay via Bank",
      value: "us_bank_account",
      amount: selectedAmount ? fees.bank : "1.00%",
    },
    {
      src: applePay,
      label: "Pay via Apple Pay",
      value: "apple_pay",
      amount: selectedAmount ? fees.applePay : "3.0%+$0.50",
    },
  ];
  const sectionRef = React.useRef(null);

  const handleScroll = () => {

    setTimeout(() => {
      if (sectionRef.current) {
        sectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 2000); //
  };
  useEffect(()=>{
    if(loader){
      handleScroll()
    }
      },[loader])
  return (
    <div className="w-full flex flex-col text-center px-[2rem] pb-8 gap-4 relative z-[100000]">
      <div className="flex flex-col gap-8">
        <div className="flex flex-row flex-wrap justify-between gap-2">
          {amountOptions.map((item) => (
            <button
              key={item}
              className={`h-[3.125rem] w-[28%] flex border border-[#979797] rounded-[0.625rem] justify-center border-opacity-[30%] items-center ${
                selectedAmount === item  ? "bg-[#e0e0e0]" : ""
              }`}
              value={item}
              onClick={() => {handleAmountButtonClick(item)

              }}
            >
              <p className="text-[#979797] text-base font-medium">{`$${item}`}</p>
            </button>
          ))}
        </div>

        <div className="flex flex-col gap-2 w-full ">
          <input
            onKeyPress={(e) => {
              if (e.key === "." || e.key === "," || e.key === "e") {
                e.preventDefault();
              }
            }}
            onPaste={(e) => {
              const pastedData = e.clipboardData.getData("text");
              if (
                pastedData.includes(".") ||
                pastedData.includes(",") ||
                isNaN(pastedData)
              ) {
                e.preventDefault();
              }
            }}
            type="number"
            id="amount"
            min={0}
            step={1}
            className={`w-full border-[1px] border-[#979797] rounded-[0.625rem] border-opacity-[30%] font-light h-[3.125rem] text-base p-[0.625rem] ${
              amountValidationError ? "border-red-500" : ""
            }`}
            placeholder="Other (in Dollars)"
            value={selectedAmount || ""}
            onChange={handleAmountChange}
          />
          {amountValidationError && (
            <p className="w-full self-start  text-red-500 text-sm text-left">
              {amountValidationError}
            </p>
          )}
        </div>
      </div>
{loader ? null:

      paymentMethods.filter((item) => !((isAndroid) && item.value === "apple_pay")).map((items) => {
        return (
          <div key={items.value}>
            <button
              className={`w-full border-[1px] border-[#979797] rounded-[0.625rem] border-opacity-[30%] h-[3.125rem] flex items-center gap-2 p-[0.625rem] cursor-pointer ${
                selectedAmount && disabledPaymentButton[items.value]
                  ? "bg-[#e0e0e0] border-[#000000]"
                  : "bg-white"
              }`}
              disabled={selectedAmount && disabledPaymentButton[items.value]}
              onClick={() => {
          handleScroll()
                if (selectedAmount) {
                  setPaymentMethod(items?.value);
                  handlePaymentClick(stripePromise, selectedAmount, items?.value);
                  setDisabledPaymentButton({
                    card: items.value === "card",
                    us_bank_account: items.value === "us_bank_account",
                    apple_pay: items.value === "apple_pay",
                  });
                } else {
                  setAmountValidationError(
                    "Please enter an amount before proceeding"
                  );

                }
              }}
            >
              <img src={items.src} alt="" className="w-[1.5rem] h-[1.5rem]" />
              <p className="text-[#1D1546] font-medium text-sm">
                {items.label}
              </p>
            </button>
            <p className="w-full self-start text-green-700 text-sm text-left">
              {`You will be charged ${items.amount}`}
            </p>
          </div>
        );
      })}

{loader ?    <div>      <CircularProgress sx={{color:"rgb(255 111 41)",width:"30px !important",height:"30px !important "}}/></div>
:
<div         ref={sectionRef}
>
      {stripePromise && clientSecret && checkout ? (
        <Elements
          stripe={stripePromise}
          key={clientSecret}
          options={{ clientSecret }}
        >
          <CheckoutForm amount={selectedAmount}/>
        </Elements>
      ) : null}</div>}
    </div>
  );
};

export default PaymentOptions;
