import React, { useState } from "react";
import profile from "../../Assets/user_profile.jpg"
import { Snackbar } from "@mui/material";
const Teams = ({ userDetails }) => {

  const [open,setOpen]=useState(false)

  function truncateText(text) {
    // Check if text length is greater than 5
    if (text.length > 5) {
      // Return the first 5 characters followed by "..."
      return text.slice(0, 5) + "...";
    }
    // If text is 5 characters or less, return it as is
    return text;
  }
  
  // Example usage
  const text = "upali";
  console.log(truncateText(text));  // Output: "upali"
  
  const longText = "upalirocks";
  console.log(truncateText(longText));  // Output: "upali..."
  
  return (
    <>

      {(userDetails?.workPlace || userDetails?.businessType === "NON-PROFIT") &&
      userDetails?.workPlaceMembersData?.length ? (
        <div className="w-[95%] mx-[auto] my-4 ">
          <h3 className="text-[#FF8245] font-semibold text-xs hover:cursor-pointer my-4" > Our Team</h3>
        <div className="flex gap-2  overflow-x-scroll custom-scrollbar pb-[20px]" >
        {userDetails.workPlaceMembersData.map((item, index) => {
            return (
              <div className="flex flex-col gap-1 items-center" >
                <div key={index} className="flex flex-wrap gap-0.5 cursor-pointer" onClick={()=>setOpen(true)}>
                <div className="h-[3rem] w-[3rem]">
                      <img
                        src={item?.profilePicture || profile}
                        alt="Member"
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>
                </div>
                {item?.fullName? (
                  <h3 className="text-xs font-semibold text-[#1D1546]">{truncateText(item?.fullName)}</h3>
                ) : null}
              </div>
            );
          })}
        </div>
        </div>
      ) : null}
        <Snackbar
      open={open}
      autoHideDuration={1000}
      onClose={() => {
       setOpen(!open)
      }}
      message="Download app to avail this feature"
      sx={{ background: "#000", color: "#fff" }}
    />
    </>
  );
};

export default Teams;
