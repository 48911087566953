const Graditude_KEYWORDS = ["recognized", "celebrated", "thanked", "Tip to"];

export const text_formatter = (label) => {
    if (!label) return label;
  return label
    .split(" ")
    .map((word) => {
      const lowerCaseWord = word.toLowerCase();
      if (Graditude_KEYWORDS.includes(lowerCaseWord)) {
        return lowerCaseWord; 
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    })
    .join(" ");
};



